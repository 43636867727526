.vape {
    animation-name: vape;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: ease-in;
}

@keyframes vape {
    from {
        opacity: 1;
    }
    
    50% {
        opacity: 0.8;
    }

    to {
        opacity: 1;
    }
}