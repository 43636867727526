.spin {
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    
    /* 50% {
        transform: rotate(20deg);
    } */

    to {
        transform: rotate(360deg);
    }
}